import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import html2PDF from 'jspdf-html2canvas';

//styles
import {
  Accordion,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Overlay,
  Popover,
  useAccordionButton,
} from 'react-bootstrap';
import { CheckCircle, Eye, InfoCircleFill, Percent, Printer, Trash, Receipt } from 'react-bootstrap-icons';
import { useReactToPrint } from 'react-to-print';
import Button from '../../components/Button';
import { formatMoney, getFromSessionStorage } from '../../constants/functions';
import { useUserContext } from '../../context/UserContext';
import { Bid, calculateOffers, getOffers, saveOffers, OffersRes } from '../../services/bidOffer';
import { AlertContent } from '../BilletSend/styles';
import { PageTitleContainer, Title } from '../ChangeAddress/styles';
import { ButtonsContainer } from '../GroupsAvailability/styles';
import { useStylization } from '../../hooks/useStylization';
import {
  AllQuotasOffer,
  BidEditButton,
  CardHead,
  Collumn,
  Forms,
  InputLine,
  Offer,
  OfferForm,
  Row,
  Tag2,
  Title2,
  PrintContainer,
  LogoSidenav,
  LogoImage,
  TextCenter,
  Separator,
  ContainerPdf,
  Bluetext,
} from './styles';
import ModalInfo from '../../components/ModalInfo';
import { OfferInput, OfferInputType } from '../../components/Form/OfferInput';
import api from '../../services/api';
import { getCurrentAccountStatementInfo } from '../../services/currentAccoutStatement';

const lanceTypeLookupTable: { [key: string]: OfferInputType } = {
  '1': 'integer',
  '2': 'percent',
  '3': 'money',
};

// @ts-ignore
function CustomToggle({ children, eventKey, onClick, hierarchy = 'primary', disabled = false }) {
  const decoratedOnClick = useAccordionButton(eventKey, onClick);

  return (
    <Button
      type="button"
      onClick={(e: any) => {
        decoratedOnClick(e);
      }}
      disabled={disabled}
      hierarchy={hierarchy}
    >
      {children}
    </Button>
  );
}

interface BidOption {
  lanceFormaContemplacaoQta: string;
  lanceCodRegraApuracao: string;
  lanceGrupo: string;
  lanceCota: string;
  lanceTipoLance: string;
  lanceOferta: string;
  embutido: boolean;
  percEmbutido: string;
  selectedRule: any;
}

export interface Regra {
  codigo: string;
  prioridade: string;
  forma: string;
  descricao: string;
  minimo: string;
  maximo: string;
  quantidade: string;
  limite: string;
  maxLanceEmbutido: string;
  tipoCalcLanceEmbutido: string;
  notMapped: string;
}

export type ConsolidadaResponse = {
  contempladas: Array<{
    grupo: string;
    cota: string;
    data: string;
    valorParcela: string;
    saldoDevedor: string;
    percentualDevedor: string;
    saldoPago: string;
    percentualPago: string;
    credito: string;
  }>;
  naoContempladas: Array<{
    grupo: string;
    cota: string;
    data: string;
    valorParcela: string;
    saldoDevedor: string;
    percentualDevedor: string;
    saldoPago: string;
    percentualPago: string;
    credito: string;
  }>;
  total: {
    credito: string;
    totalCotas: string;
    saldoPago: string;
    saldoDevedor: string;
    valorParcela: string;
  };
};

const BidOffer = () => {
  const { userData, group, quota } = useUserContext();

  const [emails, setEmails] = useState([{ type: '', value: '' }]);

  const [extract, setExtract] = useState<any>();

  const [name, setName] = useState([{ type: '', value: '' }]);

  const [showAlert] = useState<boolean>();

  const [view, setView] = useState<'all' | 'form'>('all');

  const [formStep, setFormStep] = useState(0);

  const [offersInitial, setOffersInitial] = useState<Bid[]>();

  const [isEmbeddedBidChecked, setIsEmbeddedBidChecked] = useState(false);

  const [gruposCotas, setGruposCotas] = useState<any[]>();
  const [contemplation, setContemplation] = useState<Regra[]>();
  const [offersForm, setOffersForm] = useState<BidOption[]>([]);
  const [errorsForm, setErrorsForm] = useState<string[]>([]);
  const [allFilters, setAllFilters] = useState('2');
  const [percEmbutido, setPercEmbutido] = useState<string[]>([]);
  const [globalLanceValue, setGlobalLanceValue] = useState('');

  const [revisionOffers, setRevisionOffers] = useState<any[]>([]);

  const [emissionTimestamp, setEmissionTimestamp] = useState('');
  const [emissionDate, setEmissionDate] = useState('');
  const [emissionTime, setEmissionTime] = useState('');

  const { stylization } = useStylization();

  const [finalOffers, setFinalOffers] = useState<any[]>([]);

  const getBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  };

  useEffect(() => {
    if (!userData) return;

    const cpfCnpj = getFromSessionStorage('cpfCnpj');

    api
      .get<any, ConsolidadaResponse>('/consorciado/consolidada', {
        params: { cpfCnpj },
      })
      .then((data) => {
        const naoContempladas = data.naoContempladas;

        setGruposCotas(
          naoContempladas.map((n) => ({
            cota: n.cota,
            grupo: n.grupo,
          }))
        );

        // Adicionando retorno dos emails do usuário
        setEmails(
          userData?.emails.map((email) => {
            return {
              type: '',
              value: email.endereco,
            };
          }) as []
        );

        setName([
          {
            type: '',
            value: userData?.nome || '',
          },
        ]);
      });
  }, [userData, group, quota]);

  useEffect(() => {
    if (!group || !quota) return;

    const func = async () => {
      try {
        const res1 = await getCurrentAccountStatementInfo(group, quota);
        setExtract(res1);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [group, quota]);

  const searchOffers = useCallback(async () => {
    const { res, success } = await getOffers(gruposCotas as any[]);

    if (!success) {
      // setMessage(res.err['1'] ?? 'Ocorreu um erro inesperado!');
      // handleShow();
      return;
    }

    setOffersInitial(res.lances);

    return res;
  }, [gruposCotas]);

  useEffect(() => {
    const func = async () => {
      try {
        if (!gruposCotas) return;
        const res = await searchOffers();
        if (!res) return;
        setOffersForm(
          res.lances.map(
            (lance: any) =>
              ({
                lanceGrupo: lance.lanceGrupo,
                lanceCota: lance.lanceCota,
                lanceTipoLance: lance.lanceTipoLance,
                lanceOferta: lance.lanceOferta,
                lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
                selectedRule: {
                  codigo: '1',
                  prioridade: '1',
                  forma: '1',
                },
              } as BidOption)
          )
        );
        setContemplation(res.regras);
      } catch (e) {
        console.log(e);
      }
    };
    func();
  }, [gruposCotas, searchOffers]);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const ref = useRef(null);
  const printRef = useRef(null);

  const handleTooltip = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const updateErrors = (field: string, error: boolean) => {
    if (error) {
      setErrorsForm([...errorsForm.filter((err) => err !== field), field]);
      return;
    }

    setErrorsForm(errorsForm.filter((err) => err !== field));
  };

  const hasError = (field: string) => {
    return errorsForm.some((error) => error === field);
  };

  const handleCalculate = async () => {
    try {
      let _offers = [...offersForm];
      _offers = _offers.filter((offer, index) => {
        if (!offersInitial) return false;
        // if (['0'].includes(offer.lanceOferta) && offersInitial[index].lanceOferta === ' ') {
        //
        // }
        return !(['0'].includes(offer.lanceOferta) && offersInitial[index].lanceOferta === ' ');
        // return offer.lanceOferta && offer.lanceOferta != '0' && offer.lanceTipoLance;
      });
      const res = await calculateOffers(_offers);
      if (res.err) {
        // handleShow();
        // setMessage(res.err['1']);
        setView('all');
        setTimeout(() => {
          setView('form');
        }, 10);
        return;
      }
      setRevisionOffers(res);
    } catch (e) {
      console.log(e);
    }
    setFormStep(1);
  };
  const handleSave = async () => {
    try {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // 24-hour format
      };
      const formattedDate = now.toLocaleString('pt-BR', options).replace(',', ' -');
      setEmissionTimestamp(formattedDate);

      // Extract date and time separately
      const [date, time] = formattedDate.split(' - ');
      setEmissionDate(date);
      setEmissionTime(time);

      let _offers = [...offersForm];
      _offers = _offers.filter((offer) => {
        return revisionOffers
          .map((rev) => rev.lanceCota + '' + rev.lanceGrupo)
          .includes(offer.lanceCota + '' + offer.lanceGrupo);
      });

      const percentuaisEmbutidos = _offers.map((offer) => offer.percEmbutido);
      setPercEmbutido(percentuaisEmbutidos);

      const res = await saveOffers(_offers);
      setFinalOffers(res);
    } catch (e) {
      console.log(e);
    }
    setFormStep(2);
  };
  const handleNew = () => {
    if (!offersInitial || !contemplation) return;
    console.log({ contemplation });
    let _offers = [
      ...offersInitial.map(
        (lance) =>
          ({
            lanceGrupo: lance.lanceGrupo,
            lanceCota: lance.lanceCota,
            lanceTipoLance: lance.lanceTipoLance,
            lanceOferta: lance.lanceOferta,
            lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
            selectedRule: contemplation.find((cont) => cont.forma === '99'),
          } as BidOption)
      ),
    ];
    _offers = _offers?.filter((offer) => offer.lanceOferta === ' ') as any;

    for (let i = 0; i < _offers.length; i++) {
      _offers[i].lanceOferta = '0';
      _offers[i].selectedRule = contemplation?.find((cont) => cont.forma === '99');
    }

    setIsNew(true);
    setFormStep(0);
    setOffersForm(_offers);
    setView('form');
  };
  const handleEdit = () => {
    setIsNew(false);
    if (!offersInitial || !contemplation) return;
    const _offers = [
      ...offersInitial.map(
        (lance) =>
          ({
            lanceGrupo: lance.lanceGrupo,
            lanceCota: lance.lanceCota,
            lanceTipoLance: lance.lanceTipoLance,
            lanceOferta: lance.lanceOferta,
            lanceCodRegraApuracao: lance.lanceCodRegraApuracao,
            selectedRule: contemplation.find((cont) => cont.forma === '11'),
          } as BidOption)
      ),
    ];

    for (let i = 0; i < offersInitial.length; i++) {
      if (offersInitial[i].lanceFormaContemplacaoQta < '11') {
        offersInitial[i].lanceFormaContemplacaoQta = '99';
      }
      _offers[i].lanceOferta = Number(offersInitial[i].lanceOferta.replace(',', '.')).toString();
      // _offers[i].lanceTipoLance = '2';
      _offers[i].selectedRule = contemplation?.find(
        (cont) => cont.forma === offersInitial[i].lanceFormaContemplacaoQta
      );
    }

    setOffersForm(_offers);
    setView('form');
  };
  const handleChangeTipoLance = (index: number, lanceTipoLance: string) => {
    if (!offersInitial) return;

    const _offers = [...offersForm];

    const selectedRule = _offers[index].selectedRule;

    const isFixed = Number(selectedRule?.maximo) === 0 && lanceTipoLance === offersInitial[index].lanceTipoLance;

    _offers[index] = {
      ..._offers[index],
      lanceOferta: isFixed ? Number(selectedRule?.minimo ?? 0).toString() : '',
      lanceTipoLance,
    };
    setOffersForm(_offers);
  };

  const rulesForAllCases = useMemo(() => {
    if (!contemplation) return [];

    const grupos = new Map<string, Regra[]>();

    // Agrupar os itens pelo campo 'codigo'
    for (const item of contemplation.filter((c) => c.forma !== '00')) {
      if (!grupos.has(item.codigo)) {
        grupos.set(item.codigo, []);
      }
      grupos.get(item.codigo)?.push(item);
    }

    let resultado: string[] = [];

    for (const grupo of grupos.values()) {
      const decricoes = grupo.map((item) => item.descricao);
      if (resultado.length === 0) {
        resultado = decricoes;
      } else {
        resultado = resultado.filter((descricao) => decricoes.includes(descricao));
      }
    }
    return resultado;
  }, [contemplation]);

  function handleChangeAllOffersRule(ruleDescription: string) {
    if (!ruleDescription || !contemplation || !offersInitial) return;

    setOffersForm((state) =>
      state.map((offer, index) => {
        const selectedRule = contemplation.find(
          (c) => c.descricao === ruleDescription && c.codigo === offer.lanceCodRegraApuracao
        );

        if (!selectedRule) return offer;

        const isLanceLivre = selectedRule.descricao.trim() === 'Lance Livre';
        const lanceLivreValue = offersInitial[index]?.lanceQtdeMaximoGrupo.trim().replace(',', '.');

        const lanceOferta = isLanceLivre
          ? lanceLivreValue // Use the value from offersInitial for "Lance Livre"
          : Number(selectedRule.minimo ?? 0).toString(); // Default to minimo for others

        return {
          ...offer,
          lanceOferta,
          embutido: offer.embutido,
          selectedRule,
          percEmbutido:
            offer.embutido && selectedRule.maxLanceEmbutido ? Number(selectedRule.maxLanceEmbutido).toString() : '', // Update percEmbutido if the checkbox is checked
        };
      })
    );
  }

  const updatePercEmbutido = (index: number, value: string | undefined) => {
    const _offers = [...offersForm];
    const selectedRule = _offers[index].selectedRule;
    const lanceMaximoEmbutido = selectedRule ? Number(selectedRule.maxLanceEmbutido) : 0;
    const parsedLanceOferta = parseFloat(value ? value.replace(',', '.') : '0') || 0;

    // Only update percEmbutido if embutido is true
    if (_offers[index].embutido) {
      _offers[index] = {
        ..._offers[index],
        lanceOferta: value || '0',
        percEmbutido:
          parsedLanceOferta <= lanceMaximoEmbutido
            ? parsedLanceOferta.toFixed(4) // Set percEmbutido to lanceOferta if <= max
            : lanceMaximoEmbutido.toFixed(4), // Clamp percEmbutido to max if lanceOferta > max
      };
    } else {
      _offers[index] = {
        ..._offers[index],
        lanceOferta: value || '0',
      };
    }

    setOffersForm(_offers);
  };

  const updatePercEmbutidoForAll = (value: string | undefined) => {
    const parsedLanceOferta = parseFloat(value ? value.replace(',', '.') : '0') || 0;

    setOffersForm((state) =>
      state.map((offer) => {
        const selectedRule = offer.selectedRule;
        const lanceMaximoEmbutido = selectedRule ? Number(selectedRule.maxLanceEmbutido) : 0;

        // Somente limite percEmbutido global quando embutido for verdadeiro
        let newPercEmbutido = offer.percEmbutido;

        if (offer.embutido) {
          newPercEmbutido = Math.min(parsedLanceOferta, lanceMaximoEmbutido).toFixed(4);
        }

        return {
          ...offer,
          // Não limite lanceOferta diretamente aqui, delegue ao controle individual se necessário
          lanceOferta: value || '', // Deixe o lanceOferta no valor recebido
          percEmbutido: newPercEmbutido,
        };
      })
    );
  };

  const maxClampedValue = offersForm.reduce((minValue, offer) => {
    const maxLance = offer.selectedRule?.maxLanceEmbutido || 0;
    return Math.min(minValue, maxLance);
  }, parseFloat(globalLanceValue.replace(',', '.') || '0'));

  const allFilterOptions = useMemo(() => {
    if (!offersInitial) return ['3'];

    const options = [...new Set(offersInitial.map((item) => item.lanceTipoLance))];

    options.push('3');

    if (options.length > 2) return ['3'];

    return options;
  }, [offersInitial]);

  useEffect(() => {
    if (!offersForm || !allFilters) return;
    let _offers = [...offersForm];
    _offers = _offers.map((offer) => {
      return {
        ...offer,
        lanceTipoLance: allFilters,
      };
    });
    setOffersForm(_offers);
  }, [allFilters, offersForm]);

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
  });

  const handleInvoice = () => {
    const input = document.getElementById('printRef') as HTMLElement;
    html2PDF(input, {
      jsPDF: {
        format: 'a4',
        orientation: 'portrait',
      },
      imageType: 'image/jpeg',
      imageQuality: 0.2,
      output: 'invoice.pdf',
    });
  };

  const renderFilter = (data: string) => {
    if (data === '1') {
      return 'Parcelas';
    }
    if (data === '2') {
      return '%';
    }
    if (data === '3') {
      return 'R$';
    }
  };
  // const [message, setMessage] = useState('');
  // const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);

  const pageContent = () => {
    return (
      <>
        <PageTitleContainer>
          {/* <ModalInfo message={message} show={showModal} handleClose={handleClose} header={'Alerta'}></ModalInfo> */}
          <Title>Oferta de Lance</Title>
          <ButtonsContainer>
            {view === 'all' ? (
              <>
                {offersInitial && !offersInitial.every((offer) => offer.lanceOferta !== ' ') && (
                  <CustomToggle eventKey={'0'} onClick={() => handleNew()}>
                    Novo Lance
                  </CustomToggle>
                )}

                <BidEditButton hierarchy={'secondary'} onClick={() => handleEdit()}>
                  Editar Lance
                </BidEditButton>
              </>
            ) : (
              <>
                <BidEditButton
                  hierarchy={'secondary'}
                  onClick={() => {
                    searchOffers();
                    setView('all');
                    setIsNew(false);
                  }}
                >
                  Ver todos os Lances
                </BidEditButton>
              </>
            )}
          </ButtonsContainer>
        </PageTitleContainer>

        {showAlert && (
          <AlertContent>
            <CheckCircle size={22} />
            Alterações feita com sucesso!
          </AlertContent>
        )}

        {view === 'form' ? (
          <Forms>
            <Accordion defaultActiveKey="0">
              <Card>
                <CardHead className={formStep >= 1 ? 'finished' : formStep === 0 ? 'active' : ''}>
                  <Percent size={24} />
                  Criar novo Lance
                </CardHead>

                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className={'row'}>
                      <div className="col-lg-5 col-xl-3">
                        <AllQuotasOffer>
                          <h3>Lance para todas as cotas</h3>

                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => handleChangeAllOffersRule(e.target.value)}
                          >
                            {rulesForAllCases.map((ruleDescription) => (
                              <option key={ruleDescription} value={ruleDescription}>
                                {ruleDescription.trim()}{' '}
                              </option>
                            ))}
                          </Form.Select>
                          <InputGroup>
                            <OfferInput
                              type={lanceTypeLookupTable[allFilters]}
                              onValueChange={(val) => {
                                setGlobalLanceValue(val || '');
                                updatePercEmbutidoForAll(val);
                              }}
                            />
                            {/* <Form.Control
                              aria-label="Text input with dropdown button"
                              onChange={(e) => {

                            /> */}

                            <DropdownButton
                              variant="outline-secondary"
                              title={renderFilter(allFilters)}
                              id="input-group-dropdo6"
                              align="end"
                            >
                              {allFilterOptions.map((item) => (
                                <Dropdown.Item key={item} onClick={() => setAllFilters(item)}>
                                  {item === '2' ? '%' : item === '1' ? 'Parcelas' : 'R$'}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </InputGroup>
                          <InputGroup style={{ alignItems: 'center' }}>
                            <Form.Check
                              type={'checkbox'}
                              label={`Lance embutido`}
                              id={`newEmbeddedBid`}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setIsEmbeddedBidChecked(isChecked);

                                setOffersForm((state) =>
                                  state.map((val, index) => {
                                    const selectedRule = val.selectedRule;
                                    const lanceMaximoEmbutido = selectedRule
                                      ? Number(selectedRule.maxLanceEmbutido)
                                      : 0;
                                    const currentLanceOferta = parseFloat(val.lanceOferta.replace(',', '.') || '0');

                                    const isDisabled =
                                      Number(selectedRule?.lanceMaxEmbutido) === 0 &&
                                      val.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance;

                                    if (isDisabled) {
                                      return val;
                                    }

                                    const percEmbutido = isChecked
                                      ? (currentLanceOferta <= lanceMaximoEmbutido
                                          ? currentLanceOferta
                                          : lanceMaximoEmbutido
                                        ).toFixed(4)
                                      : '';

                                    return {
                                      ...val,
                                      embutido: isChecked,
                                      percEmbutido,
                                    };
                                  })
                                );
                              }}
                            />
                            {isEmbeddedBidChecked && (
                              <OfferInput
                                style={{ borderRadius: '4px', marginLeft: '20px' }}
                                type={'percent'}
                                // Mostra o Lance Embutido Máximo se o checkbox estiver marcado
                                value={
                                  isEmbeddedBidChecked
                                    ? maxClampedValue.toFixed(4)
                                    : parseFloat(globalLanceValue.replace(',', '.') || '0').toFixed(4)
                                }
                                onChange={(e) => {
                                  let newValue = e.target.value || '';
                                  newValue = newValue.replace(',', '.');

                                  const validNumber = parseFloat(newValue);
                                  const finalValue = !isNaN(validNumber) ? validNumber : 0;

                                  setOffersForm((state) => {
                                    return state.map((offer, index) => {
                                      const isDisabled =
                                        Number(offer.selectedRule?.maxLanceEmbutido) === 0 &&
                                        offer.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance;

                                      const maxLimit = offersInitial
                                        ? Number(offersInitial[index].lanceMaxEmbutido.trim().replace(',', '.'))
                                        : 0;

                                      const clampedValue = Math.min(finalValue, maxLimit);
                                      const clampedString = clampedValue.toFixed(4);

                                      if (!isDisabled) {
                                        updateErrors(`percEmbutido${index}`, finalValue > maxLimit);
                                      }

                                      return {
                                        ...offer,
                                        percEmbutido: offer.embutido ? clampedString : offer.percEmbutido,
                                      };
                                    });
                                  });

                                  const newGlobalValue = Math.min(
                                    finalValue,
                                    ...offersForm.map((offer) => offer.selectedRule?.maxLanceEmbutido || 0)
                                  ).toFixed(4);
                                  setGlobalLanceValue(newGlobalValue);
                                }}
                              />
                            )}
                          </InputGroup>
                        </AllQuotasOffer>
                        <div className={'mt-4'}>
                          <CustomToggle
                            onClick={() => handleCalculate()}
                            eventKey={'1'}
                            disabled={offersForm.some((offer) => {
                              if (errorsForm.length > 0) {
                                return true;
                              }
                              const val = offer.selectedRule?.forma === '99' && offer.lanceOferta !== '0';
                              if (isNew) {
                                return val || (offer.selectedRule?.forma !== '99' && offer.lanceOferta === '0');
                              }
                              return val;
                            })}
                          >
                            Confirmar
                          </CustomToggle>
                        </div>
                      </div>
                      <div className="mt-4 mt-lg-0 col-lg-7 col-xl-9">
                        <div className={'d-flex flex-column gap-3 w-100'}>
                          {offersForm?.map((offer, index) => (
                            <OfferForm key={offer.lanceCota}>
                              <h3 className={'fw-bold'}>
                                {offer.lanceGrupo} / {offer.lanceCota}
                              </h3>
                              <InputLine>
                                <Collumn>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={offer?.selectedRule?.descricao.trim()}
                                    onChange={(e) => {
                                      const _offers = [...offersForm];

                                      let selectedRule = contemplation?.find(
                                        (rule) =>
                                          rule.codigo === offer.lanceCodRegraApuracao &&
                                          rule.descricao.trim() === e.target.value.trim()
                                      );

                                      const isLanceLivre = selectedRule?.descricao.trim() === 'Lance Livre';
                                      const lanceLivreValue =
                                        offersInitial && offersInitial[index]
                                          ? offersInitial[index].lanceQtdeMaximoGrupo.trim().replace(',', '.')
                                          : '0'; // Fallback value if undefined

                                      const minimoValue = Number(selectedRule?.minimo ?? 0).toString();

                                      _offers[index] = {
                                        ..._offers[index],
                                        lanceOferta: isLanceLivre
                                          ? lanceLivreValue // Use the value for "Lance Livre"
                                          : minimoValue, // Use the 'minimo' value for other selections
                                        embutido: _offers[index].embutido,
                                        selectedRule, // Ensure this updates to reflect the current selection
                                        percEmbutido:
                                          _offers[index].embutido && selectedRule?.maxLanceEmbutido
                                            ? Number(selectedRule.maxLanceEmbutido).toString()
                                            : '', // Update percEmbutido if the checkbox is checked
                                      };
                                      setOffersForm(_offers);
                                    }}
                                  >
                                    {contemplation &&
                                      contemplation
                                        .filter(
                                          (cont) => cont.forma !== '00' && cont.codigo === offer.lanceCodRegraApuracao
                                        )
                                        .map((contemplationWay, index) => (
                                          <option value={contemplationWay.descricao.trim()} key={index}>
                                            {contemplationWay.descricao}
                                          </option>
                                        ))}
                                  </Form.Select>
                                  {/* <pre>{JSON.stringify(offer, null, 2)}</pre> */}
                                  <Form.Check
                                    type={'checkbox'}
                                    label={`Lance embutido`}
                                    id={`embeddedBid-${index}`}
                                    checked={!!offersForm[index]?.embutido}
                                    disabled={false}
                                    onChange={(e) => {
                                      let selectedRule = contemplation?.find(
                                        (rule) =>
                                          rule.descricao === offer.selectedRule?.descricao &&
                                          rule.codigo === offer.lanceCodRegraApuracao
                                      );
                                      const _offers = [...offersForm];
                                      const lanceMaximoEmbutido = selectedRule
                                        ? Number(selectedRule.maxLanceEmbutido)
                                        : 0;
                                      const currentLanceOferta = parseFloat(
                                        _offers[index].lanceOferta.replace(',', '.') || '0'
                                      );

                                      const percEmbutido = e.target.checked
                                        ? (currentLanceOferta <= lanceMaximoEmbutido
                                            ? currentLanceOferta
                                            : lanceMaximoEmbutido
                                          ).toFixed(4)
                                        : '';

                                      _offers[index] = {
                                        ..._offers[index],
                                        percEmbutido,
                                        embutido: e.target.checked,
                                      };

                                      setOffersForm(_offers);
                                    }}
                                  />
                                </Collumn>
                                <Collumn>
                                  <InputGroup>
                                    <OfferInput
                                      type={lanceTypeLookupTable[offer.lanceTipoLance]}
                                      value={offer.lanceOferta}
                                      disabled={
                                        Number(offer.selectedRule?.maximo) === 0 &&
                                        offer.lanceTipoLance === (offersInitial ?? [])[index]?.lanceTipoLance
                                      }
                                      onValueChange={(val) => updatePercEmbutido(index, val)}
                                    />
                                    <DropdownButton
                                      variant="outline-secondary"
                                      title={renderFilter(offer.lanceTipoLance)}
                                      id="input-group-dropdown-2"
                                      align="end"
                                    >
                                      {(offersInitial ?? [])[index].lanceTipoLance === '2' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleChangeTipoLance(index, '2');
                                          }}
                                        >
                                          %
                                        </Dropdown.Item>
                                      )}

                                      {(offersInitial ?? [])[index].lanceTipoLance === '1' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleChangeTipoLance(index, '1');
                                          }}
                                        >
                                          Parcelas
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleChangeTipoLance(index, '3');
                                        }}
                                      >
                                        R$
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </InputGroup>
                                  <OfferInput
                                    type={'percent'}
                                    value={offer.percEmbutido}
                                    disabled={!offersForm[index].embutido}
                                    onValueChange={(val) => {
                                      const parsedValue = val ? val.replace(',', '.') : '0';

                                      // Converta parsedValue para número usando parseFloat
                                      const validParsedNumber = parseFloat(parsedValue);
                                      const finalParsedValue = !isNaN(validParsedNumber) ? validParsedNumber : 0;

                                      const _offers = [...offersForm];
                                      _offers[index] = {
                                        ..._offers[index],
                                        percEmbutido: finalParsedValue.toFixed(4),
                                      };
                                      setOffersForm(_offers);

                                      const maxLimit = offersInitial
                                        ? Number(offersInitial[index].lanceMaxEmbutido.trim().replace(',', '.'))
                                        : 0;
                                      const isAboveLimit = finalParsedValue > maxLimit;

                                      // Atualiza os erros imediatamente antes de chamar setOffersForm se necessário
                                      updateErrors(`percEmbutido${index}`, isAboveLimit);
                                    }}
                                  />
                                  <div className="redText" hidden={!hasError(`percEmbutido${index}`)}>
                                    Valor acima do permitido
                                  </div>
                                  <div className={'grayText'}>
                                    Lance Máximo:{' '}
                                    {Number(
                                      offersInitial
                                        ? offersInitial[index].lanceQtdeMaximoGrupo.trim().replace(',', '.')
                                        : 0
                                    )}
                                    % <br />
                                    Lance Mínimo:{' '}
                                    {Number(
                                      offersInitial
                                        ? offersInitial[index].lanceQtdeMinimoGrupo.trim().replace(',', '.')
                                        : 0
                                    )}
                                    %<br />
                                    Lance Embutido Máximo:{' '}
                                    {offersInitial ? offersInitial[index].lanceMaxEmbutido.trim().replace(',', '.') : 0}
                                    %
                                  </div>
                                </Collumn>
                                <Collumn className="align-items-end align-items-md-center">
                                  <Trash
                                    size={24}
                                    className={'trashIcon'}
                                    onClick={() => {
                                      const _offers = [...offersForm];
                                      _offers[index] = {
                                        ..._offers[index],
                                        lanceOferta: '0',
                                        embutido: false,
                                      };
                                      _offers[index].selectedRule = contemplation?.find((cont) => cont.forma === '99');
                                      // delete _offers[index].selectedRule;

                                      setOffersForm(_offers);
                                    }}
                                  />
                                </Collumn>
                              </InputLine>
                            </OfferForm>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <CardHead className={formStep >= 2 ? 'finished' : formStep === 1 ? 'active' : ''}>
                  <Eye />
                  Revisão do Lance
                  {/*<CustomToggle eventKey='1'>Click me!</CustomToggle>*/}
                </CardHead>
                <Accordion.Collapse eventKey="1">
                  <div className={'w-100 d-flex flex-column gap-3'}>
                    {revisionOffers &&
                      revisionOffers.map((offer, index) => (
                        <Offer>
                          <Row>
                            <Collumn>
                              <div className={'mainData'}>
                                {offer.lanceGrupo} / {offer.lanceCota}{' '}
                              </div>
                              <Tag2>
                                {offersForm.find(
                                  (of) => of.lanceGrupo === offer.lanceGrupo && offer.lanceCota.includes(of.lanceCota)
                                )?.selectedRule?.forma === '11'
                                  ? 'Lance Livre'
                                  : 'Lance Limitado'}
                              </Tag2>
                              {offersForm.findIndex(
                                (of) =>
                                  of.embutido &&
                                  of.lanceGrupo === offer.lanceGrupo &&
                                  offer.lanceCota.includes(of.lanceCota)
                              ) > -1 && <Tag2>Lance Embutido</Tag2>}
                            </Collumn>
                            {/* <pre>{JSON.stringify(offer, null, 2)}</pre> */}
                            <Collumn>
                              <div className={'title'}>Oferta</div>
                              <div className={'data'}>
                                {Number(offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')) || ''}%
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>
                                Valor Total do Lance <InfoCircleFill onClick={handleTooltip} />
                                <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>
                                  <Overlay
                                    show={show}
                                    target={target}
                                    placement="top"
                                    container={ref}
                                    containerPadding={20}
                                  >
                                    <Popover id="popover-contained">
                                      <Popover.Body>Valor do Lance a Pagar + Valor do Lance Embutido</Popover.Body>
                                    </Popover>
                                  </Overlay>
                                </div>
                              </div>
                              <div className={'data'}>{formatMoney(offer.lanceValorTotal)}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Crédito</div>
                              <div className={'data'}>
                                {(() => {
                                  const parseCurrency = (value: string): number => {
                                    const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
                                    return isNaN(parsedValue) ? 0 : parsedValue;
                                  };

                                  const valorOriginal = offer['lanceVlrCredDisponivel'].trim();
                                  const creditoDisponivel = parseCurrency(valorOriginal);

                                  const valorEmbutidoStr = offer['lanceEmbutidoVlr'].trim();
                                  const valorEmbutido = parseCurrency(valorEmbutidoStr);

                                  const credito = creditoDisponivel + valorEmbutido;
                                  return isNaN(credito) ? '-' : formatMoney(credito);
                                })()}
                              </div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Crédito Disponível</div>
                              <div className={'data'}>
                                {formatMoney(offer['lanceVlrCredDisponivel'])
                                  ? formatMoney(offer['lanceVlrCredDisponivel'])
                                  : '-'}
                              </div>
                            </Collumn>
                          </Row>
                          <Row>
                            <Collumn>
                              <div className={'title'}>Percentual Embutido</div>
                              <div className={'data'}>{`${Number(
                                offer['lanceMsgTextoEmbutido'].trim().replace(',', '.')
                              )}%`}</div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Valor Embutido</div>
                              <div className={'data'}>{formatMoney(offer['lanceEmbutidoVlr'])}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Percentual em Especie</div>
                              <div className={'data'}>
                                {(() => {
                                  const lanceOfertado = Number(
                                    offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')
                                  );
                                  const percentualEmbutido = Number(
                                    offer['lanceMsgTextoEmbutido'].trim().replace(',', '.')
                                  );
                                  const percentualEmEspecie = lanceOfertado - percentualEmbutido;
                                  return isNaN(percentualEmEspecie) ? '-' : percentualEmEspecie.toFixed(4) + '%';
                                })()}
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor em Especie</div>
                              <div className={'data'}>{formatMoney(offer['lanceAPagarVlr'])}</div>
                            </Collumn>
                          </Row>
                          {/*<Collumn>*/}
                          {/*  <div className={'title'}>*/}
                          {/*    Valor do Lance Embutido <InfoCircleFill onClick={handleTooltip} />*/}
                          {/*    <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>*/}
                          {/*      <Overlay*/}
                          {/*        show={show}*/}
                          {/*        target={target}*/}
                          {/*        placement="top"*/}
                          {/*        container={ref}*/}
                          {/*        containerPadding={20}*/}
                          {/*      >*/}
                          {/*        <Popover id="popover-contained">*/}
                          {/*          <Popover.Body>*/}
                          {/*            Valor do Lance Embutido a ser descontado do Valor do Crédito quando ocorrer a*/}
                          {/*            confirmação da Contemplação por lance*/}
                          {/*          </Popover.Body>*/}
                          {/*        </Popover>*/}
                          {/*      </Overlay>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*  <div className={'data'}>R$ 0,00</div>*/}
                          {/*</Collumn>*/}
                        </Offer>
                      ))}

                    <div>
                      <div>
                        <Title2>
                          Valor Total dos Lances Ofertados
                          <InfoCircleFill onClick={handleTooltip} />
                          <div ref={ref} className={'Valor do Lance a Pagar + Valor do Lance Embutido'}>
                            <Overlay show={show} target={target} placement="top" container={ref} containerPadding={20}>
                              <Popover id="popover-contained">
                                <Popover.Body>
                                  Se selecionada a opção de Lance Embutido, o valor do Embutido e o valor do Lance em
                                  Espécie devem ser confirmados com a Administradora no momento do pagamento do lance.
                                </Popover.Body>
                              </Popover>
                            </Overlay>
                          </div>
                          <h2>
                            {formatMoney(
                              revisionOffers.reduce(
                                (acc, cur) => Number(cur.lanceValorTotal.replace(/\./g, '').replace(',', '.')) + acc,
                                0
                              )
                            )}
                          </h2>
                        </Title2>
                        <div className={'d-flex gap-3 '}>
                          <CustomToggle eventKey={'0'} onClick={() => setFormStep(0)} hierarchy={'secondary'}>
                            Voltar
                          </CustomToggle>
                          <CustomToggle eventKey={'2'} onClick={() => handleSave()}>
                            Salvar
                          </CustomToggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>

              <Card>
                <CardHead className={formStep >= 3 ? 'finished' : formStep === 2 ? 'active' : ''}>
                  <Percent size={24}></Percent>
                  Confirmação do Lance
                  {/*<CustomToggle eventKey='0'>Click me!</CustomToggle>*/}
                </CardHead>
                <Accordion.Collapse eventKey="2">
                  <Card.Body ref={printRef}>
                    <div className={'w-100 d-flex flex-column gap-3'}>
                      {finalOffers.map((offer, index) => (
                        <Offer>
                          <Row>
                            <Collumn>
                              <div className={'mainData'}>
                                {offer.lanceGrupo} / {offer.lanceCota}{' '}
                              </div>
                              <Tag2>
                                {offersForm.find(
                                  (of) => of.lanceGrupo === offer.lanceGrupo && offer.lanceCota.includes(of.lanceCota)
                                )?.selectedRule?.forma === '11'
                                  ? 'Lance Livre'
                                  : 'Lance Limitado'}
                              </Tag2>
                              {offersForm.findIndex(
                                (of) =>
                                  of.embutido &&
                                  of.lanceGrupo === offer.lanceGrupo &&
                                  offer.lanceCota.includes(of.lanceCota)
                              ) > -1 && <Tag2>Lance Embutido</Tag2>}
                            </Collumn>
                            {/* <pre>{JSON.stringify(offer, null, 2)}</pre> */}
                            <Collumn>
                              <div className={'title'}>Data Assembleia</div>
                              <div className={'data'}>{offer.lanceDataAssembleia}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Oferta</div>
                              <div className={'data'}>{offer.lanceOfertado.replace('Porcento', ' %')}</div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Valor Total do Lance</div>
                              <div className={'data'}>
                                {' '}
                                {formatMoney(Number(offer.lanceValorTotal.replace(/\./g, '').replace(',', '.')))}
                              </div>
                            </Collumn>
                            <Collumn>
                              {offer.lanceOfertado === 'Cancelada ' ? (
                                <></>
                              ) : (
                                <>
                                  <div className={'title'}>Senha para consulta</div>
                                  <div className={'data'}>{offer.senha}</div>
                                </>
                              )}
                            </Collumn>
                          </Row>
                          <Row>
                            <Collumn>
                              <div className={'title'}>Percentual Embutido </div>
                              <div className={'data'}>
                                {Number(percEmbutido[index]?.trim().replace(',', '.')) || '0'}%
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor Embutido </div>
                              <div className={'data'}>{formatMoney(offer['lanceEmbutidoVlr'])}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Percentual em Especie </div>
                              <div className={'data'}>
                                {(
                                  Number(offer.lanceOfertado.split('Porcento')[0].trim().replace(',', '.')) -
                                  Number(percEmbutido[index]?.trim().replace(',', '.') ?? 0)
                                ).toFixed(4) + '%'}
                              </div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Valor em Especie </div>
                              <div className={'data'}>{formatMoney(offer['lanceAPagarVlr'])}</div>
                            </Collumn>

                            <Collumn>
                              <div className={'title'}>Crédito</div>
                              <div className={'data'}>
                                {(() => {
                                  const parseCurrency = (value: string): number => {
                                    const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
                                    return isNaN(parsedValue) ? 0 : parsedValue;
                                  };

                                  const valorOriginal = offer['lanceVlrCredDisponivel'].trim();
                                  const creditoDisponivel = parseCurrency(valorOriginal);

                                  const valorEmbutidoStr = offer['lanceEmbutidoVlr'].trim();
                                  const valorEmbutido = parseCurrency(valorEmbutidoStr);

                                  const credito = creditoDisponivel + valorEmbutido;
                                  return isNaN(credito) ? '-' : formatMoney(credito);
                                })()}
                              </div>
                            </Collumn>
                            <Collumn>
                              <div className={'title'}>Crédito Disponível</div>
                              <div className={'data'}>
                                {formatMoney(offer['lanceVlrCredDisponivel'])
                                  ? formatMoney(offer['lanceVlrCredDisponivel'])
                                  : '-'}
                              </div>
                            </Collumn>
                          </Row>
                        </Offer>
                      ))}

                      {process.env.REACT_APP_SEGMENTO === 'CNK' && (
                        <div
                          style={{
                            textAlign: 'center',
                            color: '#ff0b07',
                            background: '#ffc',
                            border: '1px solid #ffecb4',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            fontSize: '0.85rem',
                            fontWeight: 'bold',
                          }}
                        >
                          Em casos de lance carta avaliação, direcionar para{' '}
                          <a href="mailto:lance@kasinskiconsorcio.com.br">lance@kasinskiconsorcio.com.br</a>
                          , juntamente com o laudo de avaliação e documento do veículo em nome do titular.
                          <br />
                          <br />
                          Caso os documentos não sejam direcionados para o e-mail acima até um dia útil antes da
                          assembleia, a oferta de lance não será realizada.
                        </div>
                      )}

                      <div>
                        <Button onClick={() => handlePrint()} hideOnPrint={true}>
                          <Printer></Printer> Imprimir
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Forms>
        ) : (
          <div className={'d-flex flex-column gap-3'}>
            {offersInitial &&
              offersInitial
                .filter((offer) => Number(offer.lanceOferta) !== 0)
                .map((offer) => (
                  <Offer>
                    <div className={'d-flex flex-column'}>
                      <div className={'title'}>Grupo/ Quota</div>
                      <div className={'mainData'}>
                        {offer.lanceGrupo} / {offer.lanceCota}
                      </div>
                    </div>

                    <div className="d-flex gap-4 justify-content-between flex-wrap">
                      <div className={'d-flex flex-column'}>
                        <div className={'title'}>Data Assembleia</div>
                        <div className={'data'}>{offer.lanceDataAssembleia}</div>
                      </div>

                      <div className={'d-flex flex-column'}>
                        <div className={'title'}>Oferta</div>
                        <div className={'data'}>{Number(offer.lanceOferta.trim().replace(',', '.'))}%</div>
                      </div>
                    </div>
                  </Offer>
                ))}
          </div>
        )}
      </>
    );
  };

  return <>{userData && <div>{pageContent()}</div>}</>;
};

export default BidOffer;
