import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/Button';

export const Offer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: white;
  width: 100%;
  gap: 64px;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 24px;
    gap: 32px;
  }

  .title {
    color: #adb5bd;
    font-size: 1rem;
    font-weight: 400;

    svg {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .popover-body {
    background-color: var(--cor-primaria);
    border-top-color: var(--cor-primaria);
    color: white;
    border-radius: 4px;
  }

  .popover-arrow::after {
    border-top-color: var(--cor-primaria);
  }

  .data {
    font-size: 1.25rem;
    font-weight: 600;
    color: #495057;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .mainData {
    font-size: 1.5rem;
    font-weight: 600;
    color: #495057;
  }
`;
export const Title2 = styled.div`
  color: #adb5bd;

  svg {
    cursor: pointer;
    margin-left: 6px;
  }

  h2 {
    color: black;
    font-weight: 600;
    font-size: 2.5rem;
  }

  .popover-body {
    background-color: var(--cor-primaria);
    border-top-color: var(--cor-primaria);
    color: white;
    border-radius: 4px;
  }

  .popover-arrow::after {
    border-top-color: var(--cor-primaria);
  }
`;

export const BidEditButton = styled(Button)`
  background: none;
  color: #343a40;
  border: 1px solid var(--cor-primaria);
  transition: 0.35s;

  &:hover {
    background: var(--cor-secundaria);
    border: 1px solid var(--cor-secundaria);
    color: #fff;
    transition: 0.35s;
  }
`;

export const CardHead = styled(Card.Header)`
  border: none;
  box-shadow: none;
`;

export const Forms = styled.div`
  .accordion {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    background: #f8f9fa;
  }

  .accordion-collapse {
    background: #f8f9fa;
    padding: 24px 0;
  }

  .card {
    border: none;
  }

  .card-header {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 10px;
    height: 64px;

    border-radius: 8px;
    font-weight: 400;
    font-size: 20px;
    background: #ced4da;
    color: #6c757d;
  }
  .card-body {
    padding: 0;
    @media print {
      font-family: Arial;
      font-size: 12px !important;
    }
  }

  .active {
    color: #ffffff;
    background: #15ade0;
  }

  .finished {
    color: #ffffff;
    background: #28a745;
  }
`;

export const AllQuotasOffer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 10px;
  background: var(--cor-primaria);
  color: white;
  border-radius: 8px;

  input &:not([type='checkbox']),
  .dropdown-toggle,
  select {
    border: 1px solid #ced4da;
    background-color: #fff;
    font-size: 1rem;
    padding: 13px 16px;
    color: #6c757d;
    font-weight: 400;

    &::placeholder {
      color: #adb5bd;
    }
  }

  .form-check-input {
    &:checked {
      background-color: var(--cor-secundaria);
      border-color: var(--cor-secundaria);
    }
  }

  .dropdown-toggle {
    background: #f8f9fa;
  }
`;
export const OfferForm = styled.div`
  padding: 32px;
  display: flex;
  height: fit-content;
  gap: 16px;
  background-color: white;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;
export const InputLine = styled.div`
  gap: 12px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .trashIcon {
    color: #dc3545;
  }

  .grayText {
    color: #adb5bd;
    font-weight: 400;
  }
  .redText {
    color: #dc3545;
    font-weight: 400;
  }
`;
export const Collumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  input &:not([type='checkbox']),
  .dropdown-toggle,
  select {
    border: 1px solid #ced4da;
    background-color: #fff;
    font-size: 1rem;
    padding: 13px 16px;
    color: #6c757d;
    font-weight: 400;

    &::placeholder {
      color: #adb5bd;
    }
  }
  svg {
    margin-top: 15px;
    cursor: pointer;
  }

  select {
    padding: 13px 36px 13px 16px;
  }

  .form-check-input {
    &:checked {
      background-color: var(--cor-secundaria);
      border-color: var(--cor-secundaria);
    }
  }

  .dropdown-toggle {
    background: #f8f9fa;
    color: #adb5bd;
  }
  .title {
    color: #adb5bd;
  }
  .data {
    font-size: 1.25rem;
    font-weight: 600;
  }
`;
export const Tag2 = styled.div`
  display: flex;
  background: #adb5bd;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 10px;
  color: #ffffff;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  width: 100%;

  align-items: center;
  justify-content: space-between;

  .column-item {
    flex: 1;
  }
`;

export const LogoSidenav = styled.img`
  max-width: inherit;
`;

export const PrintContainer = styled.div`
  margin: 50px 60px 30px;

  h1 {
    font-size: 4rem; /* Double the original size */
    font-weight: 600;
    margin-bottom: 20px;
  }

  h5 {
    margin-bottom: 110px;
  }

  p,
  h5,
  span {
    /* Ensure other text elements are properly sized */
    font-size: 3rem; /* Adjust as necessary to maintain proportional sizing */
  }
`;

// Estilo para as imagens
export const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

export const Bluetext = styled.span`
  color: #020271;
`;

export const TextCenter = styled.div`
  text-align: center;
  margin: 20px 0;

  h5 {
    font-size: 3rem;
  }

  h3 {
    font-size: 3rem;
    color: #020271;
  }

  p {
    font-size: 3rem; /* Adjust paragraph size */
  }
`;

export const Separator = styled.div`
  border-bottom: 2px solid #020271;
  margin: 20px 0;
  width: 100%;
`;

// Ensure ContainerPdf and other relevant components are similarly updated
export const ContainerPdf = styled.div`
  padding: 0 80px;

  p,
  span {
    /* Adjust size for text in these elements */
    font-size: 3rem;
  }
`;
